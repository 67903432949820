import React from "react"
import { Box, Heading } from "../gazebo"

const Skills = () => {
	return (
		<Box mb={4}>
			<Heading fontSize={500} mb={2}>
				Skills, Strengths &amp; Certifications
			</Heading>
			<ul style={{ marginLeft: "2rem" }}>
				<li>Became a Licensed Social Worker (LSW) in Massachusetts in 2018</li>
				<li>Telehealth experience</li>
				<li>In-home therapy experience</li>
				<li>Active listening, thoughtful, caring, highlight strengths of the clients</li>
			</ul>
		</Box>
	)
}

export { Skills }
