import React from "react"
import { Box, Heading, Text } from "../gazebo"

const Mission = () => {
	return (
		<Box mb={4}>
			<Heading as="h2" fontSize={500} mb={2}>
				Mission
			</Heading>
			<Text mb={2}>
				To enhance the well-being of children, their families, and their communities through direct client care and policy work.
			</Text>
		</Box>
	)
}

export { Mission }
