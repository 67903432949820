import React from "react"
import { Box, Heading, Text } from "../gazebo"

const ExtraContributions = () => {
	return (
		<Box mb={4}>
			<Heading as="h2" fontSize={500} mb={2}>
				Extra Contributions &amp; Experiences
			</Heading>
			<Text mb={1}>
				<span style={{ fontWeight: 700 }}>Lowell Early Childhood Advisory Council</span>: I volunteered with other educators, social service
				workers, and child care workers in the city of Lowell to collaborate on the needs of families in the city. Our focus was on access
				to early education services, family engagement opportunities, parenting support, and other needs related to early childhood,
				including child care and health.
			</Text>
			<Text mb={1}>
				<span style={{ fontWeight: 700 }}>National Conference on Pediatric Care Management 2019</span>: This two day conference included
				lectures and workshops on topics ranging from child protection in the medical field, pediatric behavioral health in emergency care
				settings, and childhood food insecurity.
			</Text>
			<Text mb={2}>
				<span style={{ fontWeight: 700 }}>System of care meetings</span>: I attended monthly meetings with other social service providers to
				discuss the needs and resources of youth and their families. These meetings also often included presentations on local programs that
				were available to clients.
			</Text>
		</Box>
	)
}

export { ExtraContributions }
