import * as React from "react"

import Layout from "../components/layout"
import { TempContainer } from "../components/TempContainer"
import SEO from "../components/seo"
import { Experience } from "../components/Experience"
import { Box, Heading, Text } from "../gazebo"
import { ExtraContributions } from "../components/ExtraContributions"
import { Mission } from "../components/Mission"
import { Skills } from "../components/Skills"

const WorkPage = () => (
	<Layout>
		<SEO title="Work" />
		<Heading mb={4} fontSize={700} style={{ textAlign: "center" }}>
			Work
		</Heading>
		<TempContainer style={{ display: "flex", flexDirection: "column", maxWidth: "100rem" }}>
			<Mission />
			<Experience />
			<ExtraContributions />
			<Skills />
		</TempContainer>
	</Layout>
)

export default WorkPage
