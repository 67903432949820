import React from "react"
import { Box, Heading, Text } from "../gazebo"
import { TempContainer } from "./TempContainer"

const jobs = [
	{
		company: "YOU, Inc.",
		jobTitle: "In-Home Therapist (Masters Internship)",
		time: "JAN 2020 - DEC 2020",
		points: [
			"As a clinical intern, I provided intensive in-home therapy to youth and their families. My role entailed completing thorough biopsychosocial assessments, assessing for safety and required level of care, and developing strengths-based client-centered treatment plans with each family. I met with each family at least once per week for sessions and was also responsible for contacting collaterals as needed to ensure complete connected care. Clinical interventions were developed and implemented during sessions that were client-specific and tailored to meet each client’s goals. I collaborated with multiple external agencies and institutions in order to best meet the needs of each clients, including DCF, schools, and other mental health providers."
		]
	},
	{
		company: "Lowell General Hospital",
		jobTitle: "Integrated Pediatric BHSW",
		time: "APR 2018 - AUG 2019",
		points: [
			"As the sole pediatric social worker within the Fallon/Wellforce Medicaid ACO thru LGH, I provided intensive case management and care coordination services to pediatric patients (birth-21) with Fallon/Wellforce Medicaid insurance. In collaboration with the pediatric nurse care manager and community health workers, I was responsible for coordinating patient’s behavioral health care and social determinants of health. This involved collaborating with inpatient hospitals on discharge plans, making referrals for behavioral health services, and connecting with patient collaterals on an ongoing basis to ensure that the patient’s needs were being met. I met with patients in their homes, in their community, and in the hospital to make contact and to gain client trust."
		]
	},
	{
		company: "Lowell Collaborative Preschool Academy",
		jobTitle: "Family Support Specialist",
		time: "AUG 2016 - APR 2018",
		points: [
			"As a Family Support Specialist, my role was to identify psychosocial needs of the students and their families through home visits and collaboration with teachers in order to create a supportive early childhood education experience. If needs were identified, I connected families to appropriate resources. The Family Support Team was also responsible for planning events to enhance family engagement."
		]
	},
	{
		company: "North Shore Community Action Program",
		jobTitle: "Intern",
		time: "SEPT 2015 - DEC 2015",
		points: [
			"As a senior in my undergraduate social work program, my field placement was in a housing and homelessness program at a community action agency. I provided families living in the scattered site homeless shelters case management services focused on finding more permanent and sustainable housing. This involved supporting with job search, obtaining government assistance, and applying for apartments."
		]
	}
]

const Experience = () => {
	return (
		<Box mb={4}>
			<Heading mb={2} fontSize={500}>
				Experience
			</Heading>
			<div>
				{jobs.map((job) => {
					return (
						<Box key={job.company} mb={4}>
							<Text mb={1} fontSize={200}>
								{job.time}
							</Text>
							<Heading as="h4" fontSize={400} mb={2} style={{ lineHeight: 1.2 }}>
								{job.company} - {job.jobTitle}
							</Heading>
							<div style={{ marginLeft: "3rem" }}>
								{job.points.map((point) => {
									return (
										<Text key={point} style={{ marginBottom: "1.2rem" }}>
											{point}
										</Text>
									)
								})}
							</div>
						</Box>
					)
				})}
			</div>
		</Box>
	)
}

export { Experience }
